import React from 'react';
import Grid from '../layout/grid';
import Select from 'react-select';

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
			<label htmlFor={props.name}>{props.label}</label>
			{props.multiple ? (
				<Select
					readOnly={props.readOnly}
					isMulti
					defaultValue={props.value}
					isClearable={true}
					isSearchable={true}
					onChange={(item) => props.onChange(item)}
					options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
			) : (props.onChange ? (
				<Select
					readOnly={props.readOnly}
					defaultValue={props.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.value)[0] || null : null}
					isClearable={true}
					isSearchable={true}
					placeholder={(props.options || []).filter(opt => opt.id == props.value)[0] ? (props.options || []).filter(opt => opt.id == props.value)[0].valor : 'Selecione'}
					isDisabled={props.readOnly ? true : false}
					onChange={(item) => props.onChange(item ? item.value : null)}
					options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
			) : (
				<Select
					readOnly={props.readOnly}
					isClearable={true}
					isSearchable={true}
					isDisabled={props.readOnly ? true : false}
					defaultValue={props.input.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.input.value)[0] || null : null}
					onChange={(item) => props.input.onChange(item ? item.value : null)}
					onBlur={(value) => props.input.onBlur()}
					options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
			))}
       </div>
   </Grid>
)
