const INITIAL_STATE = {
	aguardando: false
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        default:
            return state;
    }
}
