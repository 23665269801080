import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setAguardando(aguardando) {
    return {
        type: 'RESULTADO_COLETIVO_SET_AGUARDANDO',
        payload: aguardando
    };
}

function getFiltro(filtro) {

	return (filtro.id_cliente ?  `id_cliente=${filtro.id_cliente}` : '')
		+ (filtro.id_unidade ? `&id_unidade=${filtro.id_unidade}` : '')
		+ (filtro.id_setor ? `&id_setor=${filtro.id_setor}` : '')
		+ (filtro.id_sexo ? `&id_sexo=${filtro.id_sexo}` : '')
		+ (filtro.tempo_empresa_inicio != null ? `&tempo_empresa_inicio=${filtro.tempo_empresa_inicio}` : '')
		+ (filtro.tempo_empresa_fim != null ? `&tempo_empresa_fim=${filtro.tempo_empresa_fim}` : '')
		+ (filtro.faixa_etaria_inicio != null ? `&faixa_etaria_inicio=${filtro.faixa_etaria_inicio}` : '')
		+ (filtro.faixa_etaria_fim != null ? `&faixa_etaria_fim=${filtro.faixa_etaria_fim}` : '')
		+ (filtro.pratica_exercicio ? `&pratica_exercicio=${filtro.pratica_exercicio == 1}` : '')
}

export function atualizarGeral(filtro) {

	return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		const [
			retornoTempoEmpresa,
			retornoFaixaEtariaEmpresa,
			retornoSexoEmpresa,
			retornoPraticaExercicio,
			retornoPraticaExercicioFrequencia,
			retornoIMC,
			retornoGorduraCorporal,
			retornoMusculoEsqueletico,
			retornoGorduraVisceral,
			retornoFumante,
			retornoDesconforto,
			retornoDesconfortoAnalitico,
			retornoPatologia
		] = await Promise.all([
			getListaTempoEmpresa(filtro),
			getListaFaixaEtariaEmpresa(filtro),
			getListaSexoEmpresa(filtro),
			getListaPraticaExercicio(filtro),
			getListaPraticaExercicioFrequencia(filtro),
			getListaIMC(filtro),
			getListaGorduraCorporal(filtro),
			getListaMusculoEsqueletico(filtro),
			getListaGorduraVisceral(filtro),
			getListaFumante(filtro),
			getListaDesconforto(filtro),
			getListaDesconfortoAnalitico(filtro),
			getListaPatologia(filtro)
		]);

		dispatch(retornoTempoEmpresa);
		dispatch(retornoFaixaEtariaEmpresa);
		dispatch(retornoSexoEmpresa);
		dispatch(retornoPraticaExercicio);
		dispatch(retornoPraticaExercicioFrequencia);
		dispatch(retornoIMC);
		dispatch(retornoGorduraCorporal);
		dispatch(retornoMusculoEsqueletico);
		dispatch(retornoGorduraVisceral);
		dispatch(retornoFumante);
		dispatch(retornoDesconforto);
		dispatch(retornoDesconfortoAnalitico);
		dispatch(retornoPatologia);

		dispatch(setAguardando(false));
	};

}

async function getListaTempoEmpresa(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/tempoEmpresa?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_TEMPO_EMPRESA_LISTADO',
		payload: { data: lista }
	};
}

async function getListaFaixaEtariaEmpresa(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/faixaEtariaEmpresa?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_FAIXA_ETARIA_EMPRESA_LISTADO',
		payload: { data: lista }
	};
}

async function getListaSexoEmpresa(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/sexo?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_SEXO_EMPRESA_LISTADO',
		payload: { data: lista }
	};
}

async function getListaPraticaExercicio(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/praticaExercicio?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_PRATICA_EXERCICIO_LISTADO',
		payload: { data: lista }
	};
}

async function getListaPraticaExercicioFrequencia(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/praticaExercicioFrequencia?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_PRATICA_EXERCICIO_FREQUENCIA_LISTADO',
		payload: { data: lista }
	};
}

async function getListaIMC(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/imc?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_IMC_LISTADO',
		payload: { data: lista }
	};
}

async function getListaGorduraCorporal(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/gorduraCorporal?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_GORDURA_CORPORAL_LISTADO',
		payload: { data: lista }
	};
}

async function getListaMusculoEsqueletico(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/musculoEsqueletico?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_MUSCULO_ESQUELETICO_LISTADO',
		payload: { data: lista }
	};
}

async function getListaGorduraVisceral(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/gorduraVisceral?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_GORDURA_VISCERAL_LISTADO',
		payload: { data: lista }
	};
}

async function getListaFumante(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/fumante?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_FUMANTE_LISTADO',
		payload: { data: lista }
	};
}

async function getListaDesconforto(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/desconforto?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_DESCONFORTO_LISTADO',
		payload: { data: lista }
	};
}

async function getListaDesconfortoAnalitico(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/desconfortoAnalitico?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_DESCONFORTO_ANALITICO_LISTADO',
		payload: { data: lista }
	};
}

async function getListaPatologia(filtro) {
	let lista = [];
	await axios.get(`${consts.API_URL}/avaliacao/patologia?${getFiltro(filtro)}`).then(result => {
		lista = result.data;
	});

	return {
		type: 'RESULTADO_COLETIVO_PATOLOGIA_LISTADO',
		payload: { data: lista }
	};
}