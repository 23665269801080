import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { 
} from './dashboardActions';

import contentCardBody from '../common/template/contentCardBody';

class Dashboard extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
    }

    render() {

        return (
			<Content>
			</Content>
        )
	}

	setDialogItens(itens) {
		this.setState({
			...this.state,
			itens: itens
		})
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboard.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
