import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function getListaAvaliacao(id_usuario) {
	const request = axios.get(`${consts.API_URL}/avaliacao/resultadoIndividual?id_usuario=${id_usuario || 0}`);
	return {
		type: 'RESULTADO_INDIVIDUAL_AVALIACAO_LISTADO',
		payload: request
	};
}
