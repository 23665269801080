import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './cargoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, getListaCliente, importar } from './cargoActions';
import ClienteUtils from '../common/utils/ClienteUtils';

class Cargo extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0
	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importar(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaCliente();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = (this.props.lista).filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome} ${item.nome_cliente}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		let paginacao = [];

		if (this.props.lista.length) {
			for (let i = 0; i < this.props.lista.length; i += 500) {				
				paginacao.push({
					inicio: i,
					fim: i + 499
				});				
			}
		} else {
			paginacao.push({
				inicio: 0,
				fim: 499
			});
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_tipo: 2 });
								}} />
						</Grid>
						{/*
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									this.fileSelector.click();
								}} />
							</Grid>*/}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
										
					<ul class='pagination pagination-sm m-0 float-right'>
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: this.state.paginacaoIndice > 0 ? this.state.paginacaoIndice - 1 : this.state.paginacaoIndice
								})}>{'<<'}
							</a>
						</li>
						
						{paginacao.map((item, i) => (
							<li class='page-item'>
								<a class='page-link'  
									style={{ 
										fontWeight: 'bold',
										cursor: 'pointer',
										backgroundColor: i == this.state.paginacaoIndice ? '#007bff' : null,
										color: i == this.state.paginacaoIndice ? '#fff' : null
									}}
									onClick={() => this.setState({
										...this.state,
										paginacaoIndice: i
									})}>{i + 1}
								</a>
							</li>
						))}
						
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: paginacao.length - 1 > this.state.paginacaoIndice ? this.state.paginacaoIndice + 1 : this.state.paginacaoIndice
								})}>{'>>'}
							</a>
						</li>
					</ul>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value, paginacaoIndice: 0 });
						}} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Cliente</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.filter((item, i) => {
								return i >= paginacao[this.state.paginacaoIndice].inicio && i <= paginacao[this.state.paginacaoIndice].fim;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.nome_cliente}</Td>
									<Td alignRight minWidth={10}>																			
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.cargo.modoTela,
	aguardando: state.cargo.aguardando,
	lista: ClienteUtils.filtrarLista(state.cargo.lista, state.auth.usuario)
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, getListaCliente, importar
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Cargo);