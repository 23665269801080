import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioPerfilReducer from '../usuarioPerfil/usuarioPerfilReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ClienteReducer from '../cliente/clienteReducer';
import UnidadeReducer from '../unidade/unidadeReducer';
import SetorReducer from '../setor/setorReducer';
import CargoReducer from '../cargo/cargoReducer';
import PatologiaReducer from '../patologia/patologiaReducer';
import ExercicioReducer from '../exercicio/exercicioReducer';
import AtividadeReducer from '../atividade/atividadeReducer';
import RecomendacaoReducer from '../recomendacao/recomendacaoReducer';
import DesconfortoReducer from '../desconforto/desconfortoReducer';
import ChamadaReducer from '../chamada/chamadaReducer';
import AvaliacaoReducer from '../avaliacao/avaliacaoReducer';
import ResultadoIndividualReducer from '../resultadoIndividual/resultadoIndividualReducer';
import ResultadoColetivoReducer from '../resultadoColetivo/resultadoColetivoReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuarioPerfil: UsuarioPerfilReducer,
	usuario: UsuarioReducer,
	cliente: ClienteReducer,
	unidade: UnidadeReducer,
	setor: SetorReducer,
	cargo: CargoReducer,
	patologia: PatologiaReducer,
	exercicio: ExercicioReducer,
	atividade: AtividadeReducer,
	recomendacao: RecomendacaoReducer,
	chamada: ChamadaReducer,
	avaliacao: AvaliacaoReducer,
	desconforto: DesconfortoReducer,
	resultadoIndividual: ResultadoIndividualReducer,
	resultadoColetivo: ResultadoColetivoReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

