import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const BarChart = (props) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);

    chart.colors.step = 2;

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'category'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      var series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'category'
      series.name = name

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      var bullet = series.bullets.push(new am4charts.LabelBullet())
      bullet.interactionsEnabled = false
      bullet.dy = 30;
      bullet.label.text = '{valueY}'
      bullet.label.fill = am4core.color('#ffffff')

      return series;
    }

    let series = props.data.map(item => item.serie);
    let categorias = [];
    props.data.forEach(item => {
      item.categorias.forEach(categoria => {
        if (!categorias.filter(categoriaItem => categoriaItem.nome == categoria.nome).length) {
          categorias.push({ ordem: categoria.ordem, nome: categoria.nome });
        }
      });
    });

    categorias.sort((a, b) => a.ordem - b.ordem);

    chart.data = categorias.map(categoria => {
      let json = `"category":"${categoria.nome}"`;
      series.forEach(serie => {
        
        const serieAux = props.data.filter(itemSerie => itemSerie.serie == serie)[0];
        if (serieAux) {

          const categoriaAux = serieAux.categorias.filter(itemCategoria => itemCategoria.nome == categoria.nome)[0];
          if (categoriaAux) {
            json += `,"${serie}":${categoriaAux.quantidade || 0}`;
          }

        }
      });

      return JSON.parse(`{${json}}`)
      
    });

    series.forEach(serie => {
      createSeries(serie, serie);
    });

    function arrangeColumns() {

      var series = chart.series.getIndex(0);

      var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            }
            else {
              series.dummyData = chart.series.indexOf(series);
            }
          })
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta

            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          })
        }
      }
    }


/*
    chart.data = props.data || [];

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'categoria';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "valor";
    series.dataFields.categoryX = "categoria";

    series.tooltipText = '{valueY.value}';

    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";

    chart.cursor = new am4charts.XYCursor();

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.label.dy = -10;
    labelBullet.label.hideOversized = false;
    labelBullet.label.truncate = false;
*/
    chart.paddingRight = 20;
    chart.paddingTop = 20;
    chart.paddingBottom = 20;

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [props.data]);

  return <div id={props.id} style={{ width: '100%', height: props.height }}></div>;
};

export default BarChart;
