const INITIAL_STATE = {
    aguardando: false,
    listaFaixaTempoEmpresa: [
        { id: 1, inicio: -10, fim: 5, valor: 'Até 5 anos' },
        { id: 2, inicio: 5.01, fim: 10, valor: 'De 5 até 10 anos' },
        { id: 3, inicio: 10.01, fim: 15, valor: 'De 10 até 15 anos' },
        { id: 4, inicio: 15.01, fim: 20, valor: 'De 15 até 20 anos' },
        { id: 5, inicio: 20.01, fim: 100, valor: 'Acima de 20 anos' }
    ],
    listaFaixaEtaria: [
        { id: 1, inicio: -10, fim: 20, valor: 'Até 20 anos' },
        { id: 2, inicio: 20.01, fim: 39.99, valor: 'De 20 até 39 anos' },
        { id: 3, inicio: 40, fim: 59.99, valor: 'De 40 até 59 anos' },
        { id: 4, inicio: 60, fim: 1000, valor: 'Acima de 60 anos' }
    ],
    listaPraticaExercicio: [
        { id: 1, valor: 'Sim' },
        { id: 2, valor: 'Não' }
    ],
    listaSexo: [{ id: 1, valor: 'Feminino' }, { id: 2, valor: 'Masculino' }],
	listaTempoEmpresa: [],
    listaFaixaEtariaEmpresa: [],
    listaSexoEmpresa: [],
    listaPraticaExercicioEmpresa: [],
    listaPraticaExercicioFrequenciaEmpresa: [],
    listaIMC: [],
    listaGorduraCorporal: [],
    listaMusculoEsqueletico: [],
    listaGorduraVisceral: [],
    listaFumante: [],
    listaDesconforto: [],
    listaDesconfortoAnalitico: [],
    listaPatologia: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'RESULTADO_COLETIVO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'RESULTADO_COLETIVO_TEMPO_EMPRESA_LISTADO':
            return {
                ...state, 
                listaTempoEmpresa: action.payload.data
            };

        case 'RESULTADO_COLETIVO_FAIXA_ETARIA_EMPRESA_LISTADO':
            return {
                ...state, 
                listaFaixaEtariaEmpresa: action.payload.data
            };

        case 'RESULTADO_COLETIVO_SEXO_EMPRESA_LISTADO':
            return {
                ...state, 
                listaSexoEmpresa: action.payload.data
            };

        case 'RESULTADO_COLETIVO_PRATICA_EXERCICIO_LISTADO':
            return {
                ...state, 
                listaPraticaExercicioEmpresa: action.payload.data
            };

        case 'RESULTADO_COLETIVO_PRATICA_EXERCICIO_FREQUENCIA_LISTADO':
            return {
                ...state, 
                listaPraticaExercicioFrequenciaEmpresa: action.payload.data
            };

        case 'RESULTADO_COLETIVO_IMC_LISTADO':
            return {
                ...state, 
                listaIMC: action.payload.data
            };

        case 'RESULTADO_COLETIVO_GORDURA_CORPORAL_LISTADO':
            return {
                ...state, 
                listaGorduraCorporal: action.payload.data
            };

        case 'RESULTADO_COLETIVO_MUSCULO_ESQUELETICO_LISTADO':
            return {
                ...state, 
                listaMusculoEsqueletico: action.payload.data
            };

        case 'RESULTADO_COLETIVO_GORDURA_VISCERAL_LISTADO':
            return {
                ...state, 
                listaGorduraVisceral: action.payload.data
            };

        case 'RESULTADO_COLETIVO_FUMANTE_LISTADO':
            return {
                ...state, 
                listaFumante: action.payload.data
            };

        case 'RESULTADO_COLETIVO_DESCONFORTO_LISTADO':
            return {
                ...state, 
                listaDesconforto: action.payload.data
            };

        case 'RESULTADO_COLETIVO_DESCONFORTO_ANALITICO_LISTADO':
            return {
                ...state, 
                listaDesconfortoAnalitico: action.payload.data
            };

        case 'RESULTADO_COLETIVO_PATOLOGIA_LISTADO':
            return {
                ...state, 
                listaPatologia: action.payload.data
            };

        default:
            return state;
    }
}
