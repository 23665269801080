import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'AVALIACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'AVALIACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('avaliacaoForm', registro)
    ];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/avaliacao`);
	return {
		type: 'AVALIACAO_LISTADO',
		payload: request
	};
}

export function salvar(registro, permanecerNaTela) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/avaliacao`, {
				...registro,
				ano: String(registro.ano || '').trim().length == 4 ? String(registro.ano || '').trim() : null,
				peso: FormatUtils.formatarValorBanco(registro.peso, 2),
				imc: FormatUtils.formatarValorBanco(registro.imc, 2),
				gordura_corporal: FormatUtils.formatarValorBanco(registro.gordura_corporal, 2),
				gordura_visceral: FormatUtils.formatarValorBanco(registro.gordura_visceral, 2),
				musculo_esqueletico: FormatUtils.formatarValorBanco(registro.musculo_esqueletico, 2),
				metabolismo_basal: FormatUtils.formatarValorBanco(registro.metabolismo_basal, 2),
				idade_corporal: FormatUtils.formatarValorBanco(registro.idade_corporal, 2),
				altura: FormatUtils.formatarValorBanco(registro.altura, 2),
				pratica_exercicio_media: FormatUtils.formatarValorBanco(registro.pratica_exercicio_media || 0, 0),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaMeta(registro));
				dispatch(initForm({
					...registro,
					id: resp.data.id
				}));
			}).catch(e => {
				console.log(e);
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/avaliacao`, {
				...registro,
				ano: String(registro.ano || '').trim().length == 4 ? String(registro.ano || '').trim() : null,
				peso: FormatUtils.formatarValorBanco(registro.peso, 2),
				imc: FormatUtils.formatarValorBanco(registro.imc, 2),
				gordura_corporal: FormatUtils.formatarValorBanco(registro.gordura_corporal, 2),
				gordura_visceral: FormatUtils.formatarValorBanco(registro.gordura_visceral, 2),
				musculo_esqueletico: FormatUtils.formatarValorBanco(registro.musculo_esqueletico, 2),
				metabolismo_basal: FormatUtils.formatarValorBanco(registro.metabolismo_basal, 2),
				idade_corporal: FormatUtils.formatarValorBanco(registro.idade_corporal, 2),
				altura: FormatUtils.formatarValorBanco(registro.altura, 2),
				pratica_exercicio_media: FormatUtils.formatarValorBanco(registro.pratica_exercicio_media || 0, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				if (!permanecerNaTela) {
					dispatch(getLista());
					dispatch(setModoTela('lista'));
				}
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/avaliacao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Patologia

export function getListaAvaliacaoPatologia(avaliacao) {
	const request = axios.get(`${consts.API_URL}/avaliacaoPatologia?id_avaliacao=${avaliacao.id || 0}`);
	return {
		type: 'AVALIACAO_PATOLOGIA_LISTADO',
		payload: request
	};
}

export function salvarPatologia(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/avaliacaoPatologia`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoPatologia({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/avaliacaoPatologia`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoPatologia({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/avaliacaoPatologia?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaAvaliacaoPatologia({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Exercicio

export function getListaAvaliacaoExercicio(avaliacao) {
	const request = axios.get(`${consts.API_URL}/avaliacaoExercicio?id_avaliacao=${avaliacao.id || 0}`);
	return {
		type: 'AVALIACAO_EXERCICIO_LISTADO',
		payload: request
	};
}

export function salvarExercicio(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/avaliacaoExercicio`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoExercicio({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/avaliacaoExercicio`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoExercicio({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/avaliacaoExercicio?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaAvaliacaoExercicio({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Atividade

export function getListaAvaliacaoAtividade(avaliacao) {
	const request = axios.get(`${consts.API_URL}/avaliacaoAtividade?id_avaliacao=${avaliacao.id || 0}`);
	return {
		type: 'AVALIACAO_ATIVIDADE_LISTADO',
		payload: request
	};
}

export function salvarAtividade(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/avaliacaoAtividade`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoAtividade({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/avaliacaoAtividade`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoAtividade({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/avaliacaoAtividade?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaAvaliacaoAtividade({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Exercicio Recomendação

export function getListaAvaliacaoExercicioRecomendacao(avaliacao) {
	const request = axios.get(`${consts.API_URL}/avaliacaoExercicioRecomendacao?id_avaliacao=${avaliacao.id || 0}`);
	return {
		type: 'AVALIACAO_EXERCICIO_RECOMENDACAO_LISTADO',
		payload: request
	};
}

export function salvarExercicioRecomendacao(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/avaliacaoExercicioRecomendacao`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoExercicioRecomendacao({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/avaliacaoExercicioRecomendacao`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoExercicioRecomendacao({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/avaliacaoExercicioRecomendacao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaAvaliacaoExercicioRecomendacao({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Recomendação

export function getListaAvaliacaoRecomendacao(avaliacao) {
	const request = axios.get(`${consts.API_URL}/avaliacaoRecomendacao?id_avaliacao=${avaliacao.id || 0}`);
	return {
		type: 'AVALIACAO_RECOMENDACAO_LISTADO',
		payload: request
	};
}

export function salvarRecomendacao(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/avaliacaoRecomendacao`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoRecomendacao({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/avaliacaoRecomendacao`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoRecomendacao({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/avaliacaoRecomendacao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaAvaliacaoRecomendacao({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Desconforto

export function getListaAvaliacaoDesconforto(avaliacao) {
	const request = axios.get(`${consts.API_URL}/avaliacaoDesconforto?id_avaliacao=${avaliacao.id || 0}`);
	return {
		type: 'AVALIACAO_DESCONFORTO_LISTADO',
		payload: request
	};
}

export function salvarDesconforto(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/avaliacaoDesconforto`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoDesconforto({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/avaliacaoDesconforto`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaAvaliacaoDesconforto({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/avaliacaoDesconforto?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaAvaliacaoDesconforto({ id: registro.id_avaliacao }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

//Meta

export function getListaMeta(avaliacao) {
	const request = axios.get(`${consts.API_URL}/meta?id_usuario=${avaliacao.id_usuario || 0}`);
	return {
		type: 'AVALIACAO_META_LISTADO',
		payload: request
	};
}

export function salvarMeta(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/meta`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaMeta({ id_usuario: registro.id_usuario, ano: registro.ano }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else if (registro.ativo) {
			axios.put(`${consts.API_URL}/meta`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaMeta({ id_usuario: registro.id_usuario, ano: registro.ano }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.delete(`${consts.API_URL}/meta?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
			}).then(resp => {
				dispatch(getListaMeta({ id_usuario: registro.id_usuario, ano: registro.ano }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function imprimirPlanilhaBioimpedancia(filtro) {

	return async (dispatch, getState) => {

		axios.post(`${consts.API_URL}/avaliacao/imprimirPlanilhaBioimpedancia`, {
			id_cliente: filtro.id_cliente,
			id_unidade: filtro.id_unidade,
			id_setor: filtro.id_setor
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}
