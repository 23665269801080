const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
    listaSelect: [],
	listaCliente: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'UNIDADE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'UNIDADE_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'UNIDADE_LISTADO':
            return {
				...state,
				lista: action.payload.data,
                listaSelect: action.payload.data.map(item => ({
                    id: item.id,
                    valor: item.nome,
                    id_cliente: item.id_cliente
                }))
			};

		case 'UNIDADE_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

        default:
            return state;
    }
}
