import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';
import LineChart from '../common/grafico/LineChart';
import BarChart from '../common/grafico/BarChart';

import { 
	atualizarGeral
} from './resultadoColetivoActions';
import { 
	getLista as getListaCliente
} from '../cliente/clienteActions';
import { 
	getLista as getListaUsuario
} from '../usuario/usuarioActions';
import { 
	getLista as getListaUnidade
} from '../unidade/unidadeActions';
import { 
	getLista as getListaSetor
} from '../setor/setorActions';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ClienteUtils from '../common/utils/ClienteUtils';

class ResultadoColetivo extends Component {

	state = {
		linhaSelecionada: null,
		filtro: {
			id_cliente: null,
			id_unidade: null,
			id_setor: null,
			id_sexo: null,
			tempo_empresa_inicio: null,
			tempo_empresa_fim: null,
			faixa_etaria_inicio: null,
			faixa_etaria_fim: null,
			pratica_exercicio: null
		}
	}

	componentDidMount() {

	}

    componentWillMount() {
		this.props.getListaCliente();
		this.props.getListaUsuario();
		this.props.getListaUnidade();
		this.props.getListaSetor();
		this.props.atualizarGeral(this.state.filtro);
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					<ContentCard>
						<ContentCardHeader>
							<Row>

								<Select
									options={this.props.listaCliente}
									label='Cliente'
									cols='12 4 4 4'
									placeholder='Selecione'
									value={this.state.filtro.id_cliente}
									onChange={value => {
										this.atualizarFiltro({
											...this.state.filtro,
											id_cliente: value	
										});
									}} />

								<Select 
									options={this.props.listaUnidade.filter(item => this.state.filtro.id_cliente && item.id_cliente == this.state.filtro.id_cliente)}
									label='Unidade'
									cols='12 4 4 4'
									placeholder='Selecione'
									value={this.state.filtro.id_unidade}
									onChange={value => {
										this.atualizarFiltro({
											...this.state.filtro,
											id_unidade: value	
										});
									}} />

								<Select 
									options={this.props.listaSetor.filter(item => this.state.filtro.id_unidade && item.id_unidade == this.state.filtro.id_unidade)}
									label='Setor'
									cols='12 4 4 4'
									placeholder='Selecione'
									value={this.state.filtro.id_setor}
									onChange={value => {
										this.atualizarFiltro({
											...this.state.filtro,
											id_setor: value	
										});
									}} />
									
								<Select 
									options={this.props.listaFaixaTempoEmpresa}
									label='Tempo de Empresa'
									cols='12 3 3 3'
									placeholder='Selecione'
									value={this.state.filtro.id_tempo_empresa}
									onChange={value => {
										const registro = this.props.listaFaixaTempoEmpresa.filter(item => item.id == value)[0];
										this.atualizarFiltro({
											...this.state.filtro,
											id_tempo_empresa: value,
											tempo_empresa_inicio: registro ? registro.inicio : null,
											tempo_empresa_fim: registro ? registro.fim : null
										});
									}} />

								<Select 
									options={this.props.listaFaixaEtaria}
									label='Faixa Etária'
									cols='12 3 3 3'
									placeholder='Selecione'
									value={this.state.filtro.id_faixa_etaria}
									onChange={value => {
										const registro = this.props.listaFaixaEtaria.filter(item => item.id == value)[0];
										this.atualizarFiltro({
											...this.state.filtro,
											id_faixa_etaria: value,
											faixa_etaria_inicio: registro ? registro.inicio : null,
											faixa_etaria_fim: registro ? registro.fim : null
										});
									}} />

								<Select 
									options={this.props.listaSexo}
									label='Sexo'
									cols='12 3 3 3'
									placeholder='Selecione'
									value={this.state.filtro.id_sexo}
									onChange={value => {
										this.atualizarFiltro({
											...this.state.filtro,
											id_sexo: value
										});
									}} />

								<Select 
									options={this.props.listaPraticaExercicio}
									label='Pratica Exercício'
									cols='12 3 3 3'
									placeholder='Selecione'
									value={this.state.filtro.pratica_exercicio}
									onChange={value => {
										this.atualizarFiltro({
											...this.state.filtro,
											pratica_exercicio: value
										});
									}} />
									
							</Row>
						</ContentCardHeader>
					</ContentCard>
									
					{!this.props.aguardando &&
					<>

					{this.tempoEmpresa()}

					{this.tempoFaixaEtaria()}

					{this.sexo()}

					{this.praticaExercicio()}
					
					{this.praticaExercicioFrequencia()}

					{this.imc()}
					
					{this.gorduraCorporal()}
					
					{this.musculoEsqueletico()}
					
					{this.gorduraVisceral()}
					
					{this.fumante()}

					{this.desconforto()}

					{this.desconfortoAnalitico()}

					{this.patologia()}

					</>}

                </Content>
            </div>
        )
    }

	atualizarFiltro(filtro) {
		this.setState({
			...this.state,
			filtro: filtro
		});
		this.props.atualizarGeral(filtro);
	}

	tempoEmpresa() {

		let listaCategoria = this.props.listaTempoEmpresa.map(item => {

			let categorias = ['Até 5 Anos', 'De 5 até 10 Anos', 'De 10 até 15 Anos', 'De 15 até 20 Anos', 'Acima de 20 Anos'];
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Tempo de Empresa</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Até 5 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>De 5 até 10 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>De 10 até 15 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>De 15 até 20 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Acima de 20 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaTempoEmpresa.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Até 5 Anos')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'De 5 até 10 Anos')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == 'De 10 até 15 Anos')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == 'De 15 até 20 Anos')[0] || {}).quantidade || 0;
											const quantidade5 = (item.categorias.filter(categoria => categoria.nome == 'Acima de 20 Anos')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4 + quantidade5;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade5}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade5 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-tempo-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	tempoFaixaEtaria() {

		let listaCategoria = this.props.listaFaixaEtariaEmpresa.map(item => {

			let categorias = ['Até 20 Anos', 'De 20 até 39 Anos', 'De 40 até 59 Anos', 'Acima de 60 Anos'];
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Faixa Etária</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Até 20 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>De 20 até 39 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>De 40 até 59 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Acima de 60 Anos</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaFaixaEtariaEmpresa.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Até 20 Anos')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'De 20 até 39 Anos')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == 'De 40 até 59 Anos')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == 'Acima de 60 Anos')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-faixa-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	sexo() {

		let listaCategoria = this.props.listaSexoEmpresa.map(item => {

			let categorias = ['Feminino', 'Masculino'];
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Sexo</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Feminino</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Masculino</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaSexoEmpresa.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Feminino')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Masculino')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-sexo-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	praticaExercicio() {

		let listaCategoria = this.props.listaPraticaExercicioEmpresa.map(item => {

			let categorias = ['Sim', 'Não'];
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Prática de Exercício Físico e/ou Esporte </h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Sim</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Não</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaPraticaExercicioEmpresa.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Sim')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Não')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-pratica-exercicio-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	praticaExercicioFrequencia() {

		let listaCategoria = this.props.listaPraticaExercicioFrequenciaEmpresa.map(item => {

			let categorias = ['Não pratica', '1 vez', '2 vezes', '3 vezes', '4 vezes', '5 vezes', '6 vezes', '7 vezes'];
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Frequência Quanto a Prática de Exercício Físico e/ou Esporte </h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Não pratica</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>1 vez</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>2 vezes</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>3 vezes</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>4 vezes</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>5 vezes</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>6 vezes</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>7 vezes</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaPraticaExercicioFrequenciaEmpresa.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Não pratica')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == '1 vez')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == '2 vezes')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == '3 vezes')[0] || {}).quantidade || 0;
											const quantidade5 = (item.categorias.filter(categoria => categoria.nome == '4 vezes')[0] || {}).quantidade || 0;
											const quantidade6 = (item.categorias.filter(categoria => categoria.nome == '5 vezes')[0] || {}).quantidade || 0;
											const quantidade7 = (item.categorias.filter(categoria => categoria.nome == '6 vezes')[0] || {}).quantidade || 0;
											const quantidade8 = (item.categorias.filter(categoria => categoria.nome == '7 vezes')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4 + quantidade5 + quantidade6 + quantidade7 + quantidade8;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade5}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade5 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade6}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade6 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade7}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade7 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-pratica-exercicio-frequencia-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	imc() {

		let listaCategoria = this.props.listaIMC.map(item => {

			let categorias = [
				'Abaixo do peso ideal (18,9)', 'Peso ideal (de 19 até 24,9)', 'Acima do peso ideal (de 25 até 29,9)', 
				'Obesidade Grau I  (de 30 até 34,9)', 'Obesidade Grau II (de 35 até 39,9)', 'Obesidade Grau III (Acima de 40)'
			];

			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>IMC</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Abaixo do peso ideal (18,9)</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Peso ideal (de 19 até 24,9)</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Acima do peso ideal (de 25 até 29,9)</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Obesidade Grau I  (de 30 até 34,9)</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Obesidade Grau II (de 35 até 39,9)</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Obesidade Grau III (Acima de 40)</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaIMC.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Abaixo do peso ideal (18,9)')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Peso ideal (de 19 até 24,9)')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == 'Acima do peso ideal (de 25 até 29,9)')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == 'Obesidade Grau I  (de 30 até 34,9)')[0] || {}).quantidade || 0;
											const quantidade5 = (item.categorias.filter(categoria => categoria.nome == 'Obesidade Grau II (de 35 até 39,9)')[0] || {}).quantidade || 0;
											const quantidade6 = (item.categorias.filter(categoria => categoria.nome == 'Obesidade Grau III (Acima de 40)')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4 + quantidade5 + quantidade6;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade5}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade5 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade6}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade6 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-imc-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	gorduraCorporal() {

		let listaCategoria = this.props.listaGorduraCorporal.map(item => {

			let categorias = ['Baixo', 'Normal', 'Alto', 'Muito Alto'];

			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Gordura Corporal</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Baixo</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Normal</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Alto</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Muito Alto</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaGorduraCorporal.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Baixo')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Normal')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == 'Alto')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == 'Muito Alto')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-gordura-corporal-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	musculoEsqueletico() {

		let listaCategoria = this.props.listaMusculoEsqueletico.map(item => {

			let categorias = ['Baixo', 'Normal', 'Alto', 'Muito Alto'];

			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Músculo Esquelético</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Baixo</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Normal</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Alto</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Muito Alto</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaMusculoEsqueletico.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Baixo')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Normal')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == 'Alto')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == 'Muito Alto')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-musculo-esqueletico-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	gorduraVisceral() {

		let listaCategoria = this.props.listaGorduraVisceral.map(item => {

			let categorias = ['Baixo', 'Normal', 'Alto', 'Muito Alto'];

			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Gordura Visceral</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Baixo</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Normal</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Alto</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Muito Alto</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaGorduraVisceral.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Baixo')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Normal')[0] || {}).quantidade || 0;
											const quantidade3 = (item.categorias.filter(categoria => categoria.nome == 'Alto')[0] || {}).quantidade || 0;
											const quantidade4 = (item.categorias.filter(categoria => categoria.nome == 'Muito Alto')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2 + quantidade3 + quantidade4;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade3}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade3 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade4}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade4 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-gordura-visceral-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	fumante() {

		let listaCategoria = this.props.listaFumante.map(item => {

			let categorias = ['Sim', 'Não'];
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Fumante</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											<Th colspan={2} alignCenter backgroundColor='#f0f0f0'>Sim</Th>
											<Th colspan={2} alignCenter backgroundColor='#ffffff'>Não</Th>
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											<Th alignCenter backgroundColor='#f0f0f0'>Contagem</Th>
											<Th alignCenter backgroundColor='#f0f0f0'>%</Th>
											<Th alignCenter backgroundColor='#ffffff'>Contagem</Th>
											<Th alignCenter backgroundColor='#ffffff'>%</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaFumante.map(item => {

											const quantidade1 = (item.categorias.filter(categoria => categoria.nome == 'Sim')[0] || {}).quantidade || 0;
											const quantidade2 = (item.categorias.filter(categoria => categoria.nome == 'Não')[0] || {}).quantidade || 0;

											const quantidadeTotal = quantidade1 + quantidade2;

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{quantidade1}</Td>
													<Td alignCenter backgroundColor='#f0f0f0'>{FormatUtils.formatarValorTela((quantidade1 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{quantidade2}</Td>
													<Td alignCenter backgroundColor='#ffffff'>{FormatUtils.formatarValorTela((quantidade2 * 100) / quantidadeTotal, 2)}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-fumante-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	desconforto() {

		let categorias = [];
		this.props.listaDesconforto.forEach(item => {
			item.categorias.forEach(categoria => {
				if (!categorias.includes(categoria.nome)) {
					categorias.push(categoria.nome);
				}
			});
		});

		categorias.sort();

		let listaCategoria = this.props.listaDesconforto.map(item => {		
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		let categoriasAux = [];
		for (let i = 0; i < categorias.length; i++) {
			const categoria = categorias[i];

			categoriasAux.push({ nome: categoria, tipo: 'Contagem', cor: i % 2 == 0 ? '#f0f0f0' : '#ffffff' });
			categoriasAux.push({ nome: categoria, tipo: '%', cor: i % 2 == 0 ? '#f0f0f0' : '#ffffff' });
		}

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Desconforto</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											{categorias.map((categoria, i) => (
												<Th key={categoria} 
													colspan={2} 
													alignCenter 
													backgroundColor={i % 2 == 0 ? '#f0f0f0' : '#ffffff'} >
													{categoria}
												</Th>
											))}
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											{categoriasAux.map((categoria, i) => (
												<Th key={i} alignCenter backgroundColor={categoria.cor} >{categoria.tipo}</Th>
											))}
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaDesconforto.map(item => {
											
											let quantidadeTotal = 0;
											categorias.map(categoriaItem => {
												const quantidade = (item.categorias.filter(categoria => categoria.nome == categoriaItem)[0] || {}).quantidade || 0;

												quantidadeTotal += quantidade;
											});

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													{categoriasAux.map(categoriaItem => {
														const quantidade = (item.categorias.filter(categoria => categoria.nome == categoriaItem.nome)[0] || {}).quantidade || 0;

														if (categoriaItem.tipo == 'Contagem') {
															return <Td alignCenter backgroundColor={categoriaItem.cor}>{quantidade}</Td>
														} else {
															return <Td alignCenter backgroundColor={categoriaItem.cor}>{FormatUtils.formatarValorTela((quantidade * 100) / quantidadeTotal, 2)}</Td>
														}
													})}
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-desconforto-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	desconfortoAnalitico() {

		let anos = [];
		let anosCategorias = [];
		let desconfortos = [];
		this.props.listaDesconfortoAnalitico.forEach(item => {
			if (!anos.includes(item.ano)) {
				anos.push(item.ano);
				anosCategorias.push({ ano: item.ano, nome: 'Confortável', cor: '#009127' });
				anosCategorias.push({ ano: item.ano, nome: 'Levemente Desconfortavel', cor: '#c96500' });
				anosCategorias.push({ ano: item.ano, nome: 'Desconforto', cor: '#cf0000' });
				anosCategorias.push({ ano: item.ano, nome: 'Total de Participantes', cor: '#3f4fa6' });
				anosCategorias.push({ ano: item.ano, nome: 'Férias/Folga', cor: '#737373' });
			}

			if (!desconfortos.includes(item.nome_desconforto)) {
				desconfortos.push(item.nome_desconforto);
			}

		});
		
		anos.sort();

		desconfortos.sort();
		
		return (
			<div>
				{this.modalDesconfortoAnalitico()}

				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Desconforto Analítico</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											{anosCategorias. filter((categoria, i) => i < 5).map((categoria, i) => (
												<Th key={categoria.nome} 
													alignCenter
													backgroundColor={categoria.cor}
													color={'#ffffff'}>
													{categoria.nome}
												</Th>
											))}
										</Tr>
									</THead>
								</Table>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Local</Th>
											{anos.map((ano, i) => (
												<Th key={ano} 
													colspan={5} 
													alignCenter 
													backgroundColor={i % 2 == 0 ? '#f0f0f0' : '#ffffff'} >
													{ano}
												</Th>
											))}
										</Tr>
										<Tr>
											{anosCategorias.map((categoria, i) => (
												<Th key={categoria.nome} 
													alignCenter
													backgroundColor={categoria.cor}
													color={'#ffffff'}>
												</Th>
											))}
										</Tr>
									</THead>
									<TBody>
										{desconfortos.map(desconforto => {
											return (
												<Tr key={desconforto}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{desconforto}</Td>
													{anosCategorias.map((categoria, i) => {
													
														let registros = this.props.listaDesconfortoAnalitico.filter(item => (
															item.ano == categoria.ano
															&& item.nome_desconforto == desconforto
															&& (
																categoria.nome == 'Confortável' && !item.ferias_folga && item.id_nivel == 1
																|| categoria.nome == 'Levemente Desconfortavel' && !item.ferias_folga && item.id_nivel == 2
																|| categoria.nome == 'Desconforto' && !item.ferias_folga && item.id_nivel == 3
																|| categoria.nome == 'Total de Participantes' && !item.ferias_folga
																|| categoria.nome == 'Férias/Folga' && item.ferias_folga
															)
														));

														return (
															<Td key={categoria.nome} 
																alignCenter
																backgroundColor={categoria.cor}
																bold>
																<a href='#' 
																	onClick={(event) => {
																		event.preventDefault();
																		this.setmodalDesconfortoAnalitico({
																			desconforto: desconforto,
																			categoria: categoria.nome,
																			cor: categoria.cor,
																			itens: registros
																		});
																	}}
																	style={{ color: '#ffffff' }} >{registros.length}</a>
															</Td>
														);
													})}
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	setmodalDesconfortoAnalitico(registro) {
		this.setState({
			...this.state,
			registroDesconfortoAnalitico: registro
		})
	}

	modalDesconfortoAnalitico() {

		if (!this.state.registroDesconfortoAnalitico) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Desconforto - Colaboradores</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setmodalDesconfortoAnalitico(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Row>
								<Grid cols='12 12 12 12'>
									<Table responsive style={{ padding: 8 }}>
										<THead>
											<Tr>
												<Th alignCenter>{this.state.registroDesconfortoAnalitico.desconforto}</Th>
												<Th alignCenter color={'#ffffff'} backgroundColor={this.state.registroDesconfortoAnalitico.cor}>{this.state.registroDesconfortoAnalitico.categoria}</Th>
												<Th alignCenter>{this.state.registroDesconfortoAnalitico.itens.length} {this.state.registroDesconfortoAnalitico.itens.length == 1 ? 'registro' : 'registros'}</Th>

											</Tr>
										</THead>
									</Table>
									<Table responsive style={{ padding: 8 }}>
										<THead>
											<Tr>
												<Th alignCenter>Ano</Th>
												<Th alignCenter>Cliente</Th>
												<Th alignCenter>Unidade</Th>
												<Th alignCenter>Setor</Th>
												<Th alignCenter>Funcionário</Th>
											</Tr>
										</THead>
										<TBody>
											{this.state.registroDesconfortoAnalitico.itens.map(item => {
												return (
													<Tr key={item.nome_funcionario}>
														<Td alignCenter>{item.ano}</Td>
														<Td alignCenter>{item.nome_cliente}</Td>
														<Td alignCenter>{item.nome_unidade}</Td>
														<Td alignCenter>{item.nome_setor}</Td>
														<Td alignCenter>{item.nome_funcionario}</Td>
													</Tr>
												);
											})}
										</TBody>
									</Table>
								</Grid>
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setmodalDesconfortoAnalitico(null)}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

	patologia() {

		let categorias = [];
		this.props.listaPatologia.forEach(item => {
			item.categorias.forEach(categoria => {
				if (!categorias.includes(categoria.nome)) {
					categorias.push(categoria.nome);
				}
			});
		});

		categorias.sort();

		let listaCategoria = this.props.listaPatologia.map(item => {		
			
			return {
				serie: String(item.ano),
				categorias: categorias.map((categoria, i) => ({
					ordem: i + 1,
					nome: categoria,
					quantidade: (item.categorias.filter(itemCategoria => itemCategoria.nome == categoria)[0] || {}).quantidade || 0
				}))
			}
		});

		let categoriasAux = [];
		for (let i = 0; i < categorias.length; i++) {
			const categoria = categorias[i];

			categoriasAux.push({ nome: categoria, tipo: 'Contagem', cor: i % 2 == 0 ? '#f0f0f0' : '#ffffff' });
			categoriasAux.push({ nome: categoria, tipo: '%', cor: i % 2 == 0 ? '#f0f0f0' : '#ffffff' });
		}

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Patologia</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<THead>
										<Tr>
											<Th rowspan={2} alignCenter backgroundColor='#c2c2c2' bold>Ano</Th>
											{categorias.map((categoria, i) => (
												<Th key={categoria} 
													colspan={2} 
													alignCenter 
													backgroundColor={i % 2 == 0 ? '#f0f0f0' : '#ffffff'} >
													{categoria}
												</Th>
											))}
											<Th colspan={2} alignCenter backgroundColor='#c2c2c2' bold>Total</Th>
										</Tr>
										<Tr>
											{categoriasAux.map((categoria, i) => (
												<Th key={i} alignCenter backgroundColor={categoria.cor} >{categoria.tipo}</Th>
											))}
											<Th alignCenter backgroundColor='#c2c2c2' bold>Contagem</Th>
											<Th alignCenter backgroundColor='#c2c2c2' bold>%</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaPatologia.map(item => {
											
											let quantidadeTotal = 0;
											categorias.map(categoriaItem => {
												const quantidade = (item.categorias.filter(categoria => categoria.nome == categoriaItem)[0] || {}).quantidade || 0;

												quantidadeTotal += quantidade;
											});

											return (
												<Tr key={item.ano}>
													<Td alignCenter backgroundColor='#c2c2c2' width={10} bold>{item.ano}</Td>
													{categoriasAux.map(categoriaItem => {
														const quantidade = (item.categorias.filter(categoria => categoria.nome == categoriaItem.nome)[0] || {}).quantidade || 0;

														if (categoriaItem.tipo == 'Contagem') {
															return <Td alignCenter backgroundColor={categoriaItem.cor}>{quantidade}</Td>
														} else {
															return <Td alignCenter backgroundColor={categoriaItem.cor}>{FormatUtils.formatarValorTela((quantidade * 100) / quantidadeTotal, 2)}</Td>
														}
													})}
													<Td alignCenter backgroundColor='#c2c2c2' bold>{quantidadeTotal}</Td>
													<Td alignCenter backgroundColor='#c2c2c2' bold>100,00%</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart 
									id={`grafico-barra-patologia-empresa`}
									height={300}
									data={listaCategoria} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaCliente: ClienteUtils.filtrarLista(state.cliente.listaSelect.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUnidade: state.unidade.listaSelect,
	aguardando: state.resultadoColetivo.aguardando,
	listaSetor: state.setor.listaSelect,
	listaUsuario: state.usuario.listaSelect,
	listaFaixaTempoEmpresa: state.resultadoColetivo.listaFaixaTempoEmpresa,
	listaFaixaEtaria: state.resultadoColetivo.listaFaixaEtaria,
	listaPraticaExercicio: state.resultadoColetivo.listaPraticaExercicio,
	listaSexo: state.resultadoColetivo.listaSexo,
	listaTempoEmpresa: state.resultadoColetivo.listaTempoEmpresa,
	listaFaixaEtariaEmpresa: state.resultadoColetivo.listaFaixaEtariaEmpresa,
	listaSexoEmpresa: state.resultadoColetivo.listaSexoEmpresa,
	listaPraticaExercicioEmpresa: state.resultadoColetivo.listaPraticaExercicioEmpresa,
	listaPraticaExercicioFrequenciaEmpresa: state.resultadoColetivo.listaPraticaExercicioFrequenciaEmpresa,
	listaIMC: state.resultadoColetivo.listaIMC,
	listaGorduraCorporal: state.resultadoColetivo.listaGorduraCorporal,
	listaMusculoEsqueletico: state.resultadoColetivo.listaMusculoEsqueletico,
	listaGorduraVisceral: state.resultadoColetivo.listaGorduraVisceral,
	listaFumante: state.resultadoColetivo.listaFumante,
	listaDesconforto: state.resultadoColetivo.listaDesconforto,
	listaDesconfortoAnalitico: state.resultadoColetivo.listaDesconfortoAnalitico,
	listaPatologia: state.resultadoColetivo.listaPatologia
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaCliente, getListaUsuario, getListaUnidade, getListaSetor, 
	atualizarGeral
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ResultadoColetivo);