import React from 'react'
import Grid from '../layout/grid'

export default props => {
	return (
		<Grid cols={props.cols} style={{ backgroundColor: props.backgroundColor }}>
	        <div className='form-group'>
	            {props.label &&
				<label htmlFor={props.name}>{props.label}</label>}
				{props.onChange ? (
					<input {...props.input} className='form-control'
						style={{ textAlign: props.textAlign }}
		                 placeholder={props.placeholder}
		                 readOnly={props.readOnly} type={props.type}
						 value={props.value}
						 onChange={props.onChange} />
				) : (
					<input {...props.input} className='form-control'
						style={{ textAlign: props.textAlign }}
		                 placeholder={props.placeholder}
		                 readOnly={props.readOnly} type={props.type} />
				)}
	        </div>
	    </Grid>
	);
}
