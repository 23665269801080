const INITIAL_STATE = {
	listaAvaliacao: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'RESULTADO_INDIVIDUAL_AVALIACAO_LISTADO':
            return {
                ...state, 
                listaAvaliacao: action.payload.data
            };

        default:
            return state;
    }
}
