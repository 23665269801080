import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const LineChart = (props) => {
  const chartRef = useRef(null);
  
  useLayoutEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);
    
    chart.data = props.data || [];

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'categoria';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "valor";
    series.dataFields.categoryX = "categoria";

    series.tooltipText = '{valueY.value}';

    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    
    chart.cursor = new am4charts.XYCursor();

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.label.dy = -10;
    labelBullet.label.hideOversized = false;
    labelBullet.label.truncate = false;

    chart.paddingRight = 20;
    chart.paddingTop = 20;
    chart.paddingBottom = 20;

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [props.data]);

  return <div id={props.id} style={{ width: '100%', height: props.height }}></div>;
};

export default LineChart;
