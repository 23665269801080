import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario } from '../../auth/authActions';

class Menu extends Component {

	state = {
		acessoAberto: null,
		cadastroAberto: null
	}

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		// if (this.props.usuarioCarregado) {
		// 	if (!this.props.usuarioCarregado.acesso_usuario && (selecionado == '/usuario' || selecionado == '/usuarioPerfil')) {
		// 		window.location = '/';
		// 	}
		// }

		if (selecionado != '/' 
			&& this.state.acessoAberto == null
			&& this.state.cadastroAberto == null 
			&& this.state.configuracaoAberto == null
		) {
			if (selecionado == '/usuarioPerfil' || selecionado == '/usuario') {
				this.setState({
					...this.state,
					acessoAberto: true
				});
			}
			
			if (selecionado == '/cliente' || selecionado == '/unidade' || selecionado == '/setor' 
				|| selecionado == '/cargo' || selecionado == '/usuarioColaborador') {
				this.setState({
					...this.state,
					cadastroAberto: true
				});
			}

			if (selecionado == '/patologia' || selecionado == '/exercicio' || selecionado == '/atividade'
				|| selecionado == '/recomendacao' || selecionado == '/desconforto') {
				this.setState({
					...this.state,
					configuracaoAberto: true
				});
			}
		}		
		
		return (
			<nav className="mt-2">
								    
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					<MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />
					
					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuTree icon={'fas fa-user-lock'} label={'Acesso'}
						display={this.state.acessoAberto}
						onClick={() =>
							this.setState({
								...this.state,
								acessoAberto: !this.state.acessoAberto,
							})
						} >
						{/* <MenuItem submenu path='usuarioPerfil' label='Perfil de Usuário' icon='fas fa-user-tag' active={selecionado == '/usuarioPerfil'} /> */}
						<MenuItem submenu path='usuario' label='Usuário' icon='fas fa-id-card-alt' active={selecionado == '/usuario'} />
					</MenuTree>}

					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuTree icon={'fas fa-cog'} label={'Cadastro'}
						display={this.state.cadastroAberto}
						onClick={() =>
							this.setState({
								...this.state,
								cadastroAberto: !this.state.cadastroAberto,
							})
						} >
						<MenuItem submenu path='cliente' label='Cliente' icon='fas fa-user-edit' active={selecionado == '/cliente'} />
						<MenuItem submenu path='unidade' label='Unidade' icon='fas fa-map-marker-alt' active={selecionado == '/unidade'} />
						<MenuItem submenu path='setor' label='Setor' icon='fas fa-sitemap' active={selecionado == '/setor'} />
						<MenuItem submenu path='cargo' label='Cargo' icon='fas fa-id-card' active={selecionado == '/cargo'} />
						<MenuItem submenu path='usuarioColaborador' label='Colaborador' icon='fas fa-users' active={selecionado == '/usuarioColaborador'} />
					</MenuTree>}

					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuTree icon={'fas fa-cogs'} label={'Configuração'}
						display={this.state.configuracaoAberto}
						onClick={() =>
							this.setState({
								...this.state,
								configuracaoAberto: !this.state.configuracaoAberto,
							})
						} >
						<MenuItem submenu path='patologia' label='Patologia' icon='fas fa-stethoscope' active={selecionado == '/patologia'} />
						<MenuItem submenu path='exercicio' label='Exercício' icon='fas fa-biking' active={selecionado == '/exercicio'} />
						<MenuItem submenu path='atividade' label='Atividade' icon='fas fa-walking' active={selecionado == '/atividade'} />
						<MenuItem submenu path='recomendacao' label='Recomendação' icon='fas fa-clipboard-list' active={selecionado == '/recomendacao'} />
						<MenuItem submenu path='desconforto' label='Desconforto' icon='fas fa-user-injured' active={selecionado == '/desconforto'} />
					</MenuTree>}

					{this.props.usuarioCarregado && (
						(this.props.usuarioCarregado.id_perfil == 1 || this.props.usuarioCarregado.id_perfil == 2)
					) &&
					<MenuItem submenu path='chamada' label='Chamada' icon='fas fa-clipboard-check' active={selecionado == '/chamada'} />}
					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuItem submenu path='chamadaMensal' label='Chamada - Mês' icon='fas fa-calendar' active={selecionado == '/chamadaMensal'} />}
					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuItem submenu path='avaliacao' label='Avaliação' icon='fas fa-clipboard-list' active={selecionado == '/avaliacao'} />}
					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuItem submenu path='resultadoIndividual' label='Resultado Individual' icon='fas fa-chart-line' active={selecionado == '/resultadoIndividual'} />}
					{this.props.usuarioCarregado && (
						this.props.usuarioCarregado.id_perfil == 1
					) &&
					<MenuItem submenu path='resultadoColetivo' label='Resultado Coletivo' icon='fas fa-chart-bar' active={selecionado == '/resultadoColetivo'} />}
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
